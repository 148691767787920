import React, { useState } from 'react';

const Hero = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);

  const handleSignUpClick = () => {
    setShowEmailInput(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Email submitted:', email);
    setIsSubmitted(true);
    // Here you would typically send the email to your backend
  };

  return (
    <section className="min-h-screen flex items-center justify-center bg-secondary text-primary px-4">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl md:text-5xl font-light mb-8 max-w-3xl mx-auto">
          Connecting retail businesses with on-demand merchandising
        </h2>
        <div className="space-y-4 mb-8">
          <p className="text-xl md:text-2xl font-semibold">
            Interested in increasing sales for your business?
          </p>
          <p className="text-xl md:text-2xl font-semibold">
            Looking for high-paying gig work?
          </p>
          <p className="text-lg md:text-xl">
            Leave your email for updates as we prepare for launch:
          </p>
        </div>
        {!showEmailInput ? (
          <button
            onClick={handleSignUpClick}
            className="font-semibold py-3 px-8 rounded-full bg-primary text-secondary hover:bg-opacity-90 transition duration-300"
          >
            Sign Up
          </button>
        ) : (
          <form onSubmit={handleSubmit} className="max-w-md mx-auto flex flex-col sm:flex-row">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="flex-grow px-4 py-3 rounded-full sm:rounded-r-none mb-2 sm:mb-0 focus:outline-none border border-primary transition-border duration-300 focus:border-accent"
              required
              disabled={isSubmitted}
            />
            <button
              type="submit"
              className={`font-semibold py-3 px-8 rounded-full sm:rounded-l-none transition duration-300 flex items-center justify-center ${
                isSubmitted
                  ? 'bg-green-500 text-white cursor-default'
                  : 'bg-primary text-secondary hover:bg-opacity-90'
              }`}
              disabled={isSubmitted}
            >
              {isSubmitted ? (
                <>
                  Subscribed
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                </>
              ) : (
                <>
                  Subscribe
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </>
              )}
            </button>
          </form>
        )}
      </div>
    </section>
  );
};

export default Hero;