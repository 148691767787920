import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="absolute top-0 left-0 right-0 z-10">
      <div className="container mx-auto px-6 py-8">
        <h1 className="text-2xl font-semibold">StockSwift</h1>
      </div>
    </header>
  );
};

export default Header;