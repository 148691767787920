import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="py-8 text-center text-sm text-gray-500">
      <div className="container mx-auto px-4">
        <p>&copy; 2024 StockSwift. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;